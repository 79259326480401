import { useUser, withPageAuthRequired } from '@auth0/nextjs-auth0'
import { Button, Typography } from '@mui/material'
import Head from 'next/head'
import React, { useState } from 'react'
import { useUpsert_App_UserMutation } from '../generated/graphql'
import { breadcrumbsTitle } from '../utils/BreadcrumbsTitle'
import type { NextPage } from 'next'
import { useLogger } from 'utils/Logger'

const Home: NextPage = () => {

    const title = 'ホーム'

    const log = useLogger()
    const { user } = useUser()
    const [userRole, setRole] = useState<any>('user')

    const getRole = (user: any) => {
        return user['https://hasura.io/jwt/claims']['x-hasura-default-role']
    }
    const [upsertAppUserMutation] = useUpsert_App_UserMutation({
        onCompleted: (_data: any) => {
            const role = getRole(user)
            setRole(role)
            if (role === 'user') {
                location.href = '/home'
            }
        },
    })

    React.useEffect(() => {
        if (user) {
            // auth0 で (アカウント作成 or ログイン) 直後に、本ページへリダイレクトされる。
            // その際、auth0 user id を紐付ける。
            // auth0 user id は x-hasura-user-id と同一。
            // x-hasura-user-id は Hasura の Column presets 機能でセットされる。
            upsertAppUserMutation({
                variables: {
                    email: user.email,
                },
            }).catch((e: any) => {
                log.error("", e)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upsertAppUserMutation, user])

    return (
        <>
            {userRole === 'user' ? (
                <Typography variant="h3" sx={{position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                    {'NileBankに遷移します。'}
                </Typography>
            ) : (
                <>
                    <Head>
                        <title>{breadcrumbsTitle([title])}</title>
                    </Head>
                    <>
                        <p>{title}</p>
                        <Button
                            onClick={() => {
                                throw new Error('Sentry Frontend Error')
                            }}
                        >
                            Sentry Test
                        </Button>
                    </>
                </>
            )}
        </>
    )
}

export default withPageAuthRequired(Home)
